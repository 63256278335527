<template>
  <Popover :config="labelPopoverConfig">
    <div v-if="labelPopoverConfig.show">
      <div class="color-palette-container">
        <input
          @keyup="saveLabelName"
          @keyup.enter="onEnter()"
          v-model="localLabelName"
          placeholder="Label Name"
          class="border-b border-primary-300 text-sm w-full py-1 px-2 placeholder-primary-400 text-primary-800 focus:outline-none focus:border-primary block"
        />

        <div class="mt-2 color-list">
          <div
            :key="index"
            class="cursor-pointer flex items-center justify-center color-item"
            :class="{'active': labelColor===color.code}"
            :style="{'border-color': color.code}"
            @mouseover="color.isFocused=true"
            @mouseleave="color.isFocused=false"
            v-for="(color,index) in colors"
            @click="selectLabelColor(color.code)"
          >
            <i
              class="fa fa-circle"
              :style="{color:color.code}"
            />
          </div>

          <div
            class="cursor-pointer flex items-center justify-center remove-label-icon"
            @click="removeLabel"
          >
            <i class="fas fa-ban" v-tooltip.right="'Remove label'" />
          </div>
        </div>
      </div>
    </div>
  </Popover>
</template>

<script>
import store from '@/services/store'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
export default {
  props: {
    labelName: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: ''
    },
    paraPos: {
      type: Number,
      default: 1
    }
  },
  components: {
    Popover: () => import('./Popover/Popover')
  },
  data () {
    return {
      colors: [
        { code: '#E2462C', isFocused: false },
        { code: '#52AADB', isFocused: false },
        { code: '#F8C450', isFocused: false },
        { code: '#EC5B16', isFocused: false },
        { code: '#F20D70', isFocused: false },
        { code: '#B4C236', isFocused: false },
        { code: '#53B745', isFocused: false },
        { code: '#8338EC', isFocused: false },
        { code: '#AE33D9', isFocused: false },
        { code: '#233AC9', isFocused: false },
        { code: '#12334B', isFocused: false }
      ],
      localLabelName: ''
    }
  },
  computed: {
    labelPopoverConfig () {
      return store.state.editor.labelPopoverConfig
    }
  },
  methods: {
    saveLabelName () {
      myProseEditor.setParagraphMeta(this.paraPos, { 'labelName': this.localLabelName, 'labelColor': this.labelColor })
    },
    selectLabelColor (code) {
      myProseEditor.setParagraphMeta(this.paraPos, { 'labelName': this.labelName, 'labelColor': code })
      // console.log(code)
    },
    removeLabel () {
      myProseEditor.setParagraphMeta(this.paraPos, { 'labelName': '', 'labelColor': '' })
    },
    resetFocus () {
      this.colors.forEach(function (color) {
        color.isFocused = false
      })
    },
    onEnter: function() {
      store.commit('editor/closeLabelPopover')
    }
  }
}
</script>

<style lang="scss" scoped>
.color-palette-container {
  border-radius: 8px;
  padding: 10px 9px;
  position: absolute;
  width: 150px;
  box-shadow: 0px 0px 6px rgba(0, 2, 40, 0.1);
  border-radius: 5px;
  background: white;

  .color-list {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;

    .color-item {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      border-width: 2px;
      padding: 1px;
      margin: 2px 2px 0 0;

      &:not(.active):not(:hover) {
        border-color: transparent !important;
      }
    }

    .remove-label-icon {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      border-width: 2px;
      padding: 1px;
      margin: 2px 2px 0 0;
      border-color: transparent !important;

      i {
        color: #D1D5DB;
      }
    }
  }
}
</style>
